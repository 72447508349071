import React, {Suspense} from 'react';

const ProductTargetAndCommissionComponent = React.lazy(() => import('../Component/ProductTargetAndCommissionComponent/ProductTargetAndCommissionComponent'));

function ProductTargetAndCommissionPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <ProductTargetAndCommissionComponent/>
            </Suspense>
        </>
    );
}

export default ProductTargetAndCommissionPage;