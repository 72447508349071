import {Link} from "react-router-dom";
import React from "react";
import {FaBars} from "@react-icons/all-files/fa/FaBars";
import {BiDownArrow} from "@react-icons/all-files/bi/BiDownArrow";
import Auth from "../../../Utilities/Auth";


class SideNavUtilities {
    constructor() {
        this.isPermitted = (permission_property = ["permission_property"]) => {
            // all permissions
            const permission = JSON.parse(Auth.header()?.userdetail || "{}").role?.permission || {}
            try {
                let permitted = false
                for (let property of permission_property) {
                    if (property == "*" || permission[property] == true) {
                        permitted = true
                        break;
                    }
                }
                return permitted
            } catch (e) {
                return false
            }
        }
    }

    // toggle bar
    static toggleBar() {
        // toggle on click method
        const sideBarToggleOnClick = (event) => {
            event.preventDefault();
            document.body.classList.toggle('sb-sidenav-toggled');
            localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
        }
        // toggle view return
        return <button
            className="toggleBtn btn btn-link btn-sm me-4 me-lg-0"
            onClick={event => sideBarToggleOnClick(event)}
        >
            {/*toggle icon*/}
            <FaBars/>
        </button>
    }

    // sidebar header
    static header() {
        return <div className="side-nav-header">
            <Link to={'/dashboard'} className="nav-link text-center" href="index.html">
                <span className="text-center fw-bolder text-white">My Point</span>
            </Link>
        </div>
    }

    // sidebar footer
    static footer() {
        return <div className="sb-sidenav-footer">
            <div className="small">Ummah Soft & Technology</div>
            <div className="small">Phone - +91 9832132183</div>
        </div>
    }

    // marker
    static marker(markName = "marker name", permission = ["*"]) {
        return new SideNavUtilities().isPermitted(permission) == true ?
            <div className="sb-sidenav-menu-heading pb-0 pt-2">{markName.toUpperCase()}</div> :
            false
    }

    // side nav single link
    static menu(menuName, linkUrl, permission = ["*"], icon) {
        return new SideNavUtilities().isPermitted(permission) == true ?
            <Link to={linkUrl} className="nav-link linkBorder">
                <div className="sb-nav-link-icon">{icon}</div>
                <span>{menuName}</span>
            </Link> : false
    }

    // side nav dropdown
    static dropDownMenu(
        dropDown = {
            name: "N/A",
            icon: "icon",
            permissions: ["*"]
        }, menus = [
            {
                menuName: "menuName",
                link: "/dashboard",
                permissions: ["*"]
            }
        ]) {
        // view return
        return <>
            {/*drop down menu name*/}
            {new SideNavUtilities().isPermitted(dropDown.permissions) == true &&
            <a className="nav-link linkBorder collapsed mb-1" href="#" data-bs-toggle="collapse"
               data-bs-target={`#${dropDown.name}`} aria-expanded="false"
               aria-controls={dropDown.name}>
                <div className="sb-nav-link-icon">{dropDown.icon}</div>
                {dropDown.name}
                {/*<div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"/></div>*/}
                <div className="sb-sidenav-collapse-arrow"><BiDownArrow/></div>
            </a>}


            {/*dorp down menues*/}
            <div className="collapse" id={dropDown.name} aria-labelledby="headingOne"
                 data-bs-parent="#sidenavAccordion">
                <nav className="sb-sidenav-menu-nested nav">
                    {menus.length != 0 && menus.map((menu, key) => {
                        return new SideNavUtilities().isPermitted(menu.permissions) == true && <div key={key}>
                            <Link className="nav-link dropdownMenu  mb-2" to={menu.link}>{menu.menuName}</Link>
                        </div>
                    })}
                </nav>
            </div>
        </>
    }
}

// export
export default SideNavUtilities