import React from 'react';
import {FaProductHunt} from "@react-icons/all-files/fa/FaProductHunt";
import SideNavUtilities from "./Utilities/SideNavUtilities";
import {BiPurchaseTagAlt} from "@react-icons/all-files/bi/BiPurchaseTagAlt";
import {BiBuildings} from "@react-icons/all-files/bi/BiBuildings";
import {RiFolderReceivedFill} from "@react-icons/all-files/ri/RiFolderReceivedFill";
import {GoDatabase} from "@react-icons/all-files/go/GoDatabase";
import {FaShoppingCart} from "@react-icons/all-files/fa/FaShoppingCart";
import {CgShoppingCart} from "@react-icons/all-files/cg/CgShoppingCart";
import {AiOutlineAppstoreAdd} from "@react-icons/all-files/ai/AiOutlineAppstoreAdd";
import {TbUsers} from "react-icons/tb";
import {MdSecurity} from "@react-icons/all-files/md/MdSecurity";


function SideBar(props) {

    return (
        <>
            <div id="layoutSidenav_nav">
                <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    {/*side nav header*/}
                    {SideNavUtilities.header()}
                    <div className="sb-sidenav-menu">
                        <div className="nav bg-transparent">
                            {/*side nav toggle button*/}
                            {SideNavUtilities.toggleBar()}

                            {/*****************menu initialize here***************/}
                            {/*link marker*/}
                            {SideNavUtilities.marker("config", [
                                "prodCatUnit_c", "prodCatUnit_r", "prodCatUnit_u", "prodCatUnit_d",
                                "vendor_c", "vendor_r", "vendor_u", "vendor_d",
                                "customer_c", "customer_r", "customer_u", "customer_d"
                            ])}

                            {/*product link*/}
                            {SideNavUtilities.menu(
                                "Product",
                                "/product",
                                ["prodCatUnit_c", "prodCatUnit_r", "prodCatUnit_u", "prodCatUnit_d"],
                                <FaProductHunt/>
                            )}

                            {/*vendor link*/}
                            {SideNavUtilities.menu(
                                "Vendor",
                                "/vendor",
                                ["vendor_c", "vendor_r", "vendor_u", "vendor_d"],
                                <BiBuildings/>
                            )}
                            {/*customer link*/}
                            {SideNavUtilities.menu(
                                "Customer",
                                "/customer",
                                ["customer_c", "customer_r", "customer_u", "customer_d"],
                                "C"
                                // <TbLetterC/>
                            )}

                            {/********************  purchase  ***********************/}
                            {/*link marker*/}
                            {SideNavUtilities.marker("Purchase", ["pur_c", "pur_r", "pur_u", "pur_d", "ac_vendor_c"])}
                            {/*drop down menue*/}
                            {SideNavUtilities.dropDownMenu(
                                {
                                    name: "Purchase",
                                    icon: <BiPurchaseTagAlt/>,
                                    permissions: ["pur_c", "pur_r", "pur_u", "pur_d", "ac_vendor_c"]
                                },
                                [
                                    {
                                        menuName: "Purchase",
                                        link: "/purchase",
                                        permissions: ["pur_c"]
                                    },
                                    {
                                        menuName: "All chalan",
                                        link: "/allChalan",
                                        permissions: ["pur_r", "pur_c", "pur_u", "ac_vendor_c"]
                                    }, {
                                    menuName: "Chalan update",
                                    link: "/chalanUpdate",
                                    permissions: ["pur_u"]
                                },
                                ])}
                            {/********************  purchase end  ***********************/}

                            {/********************  stock  ***********************/}
                            {/*link marker*/}
                            {SideNavUtilities.marker("Store", ["stock_c", "stock_r", "stock_u", "stock_d"])}
                            {/*drop down menue*/}
                            {SideNavUtilities.dropDownMenu(
                                {
                                    name: "Store",
                                    icon: <AiOutlineAppstoreAdd/>,
                                    permissions: ["stock_c", "stock_r", "stock_u", "stock_d"]
                                },
                                [
                                    {
                                        menuName: "Stock",
                                        link: "/stockedItems",
                                        permissions: ["stock_r"]
                                    }, {
                                    menuName: "Pending",
                                    link: "/pendingChalan",
                                    permissions: ["stock_u"]
                                }, {
                                    menuName: "Prev stock",
                                    link: "/addPrevStock",
                                    permissions: ["stock_c"]
                                }
                                ])}
                            {/********************  stock end  ***********************/}

                            {/********************  sale  ***********************/}
                            {/*link marker*/}
                            {SideNavUtilities.marker("Sale", ["invoice_c", "invoice_r", "invoice_u", "invoice_d", "ac_customer_c"])}
                            {/*drop down menue*/}
                            {SideNavUtilities.dropDownMenu(
                                {
                                    name: "Sale",
                                    icon: <CgShoppingCart/>,
                                    permissions: ["invoice_c", "invoice_r", "invoice_u", "invoice_d", "ac_customer_c"]
                                },
                                [
                                    {
                                        menuName: "Local sale",
                                        link: "/localSale",
                                        permissions: ["invoice_c"]
                                    }, {
                                    menuName: "Customer sale",
                                    link: "/customerSale",
                                    permissions: ["invoice_c"]
                                }, {
                                    menuName: "All invoices",
                                    link: "/allInvoices",
                                    permissions: ["invoice_r", "invoice_c", "invoice_u", "ac_customer_c"]
                                }, {
                                    menuName: "Due collection",
                                    link: "/i_collection",
                                    permissions: ["invoice_c", "invoice_u", "ac_customer_c"]
                                }, {
                                    menuName: "Invoices update",
                                    link: "/invoiceUpdate",
                                    permissions: ["invoice_u"]
                                },

                                ])}
                            {/********************  sale end  ***********************/}

                            {/********************  accounts  ***********************/}
                            {/*link marker*/}
                            {SideNavUtilities.marker("Accounts", ["ac_c", "pur_u", "ac_customer_c", "ac_customer_r", "ac_customer_u", "ac_vendor_c", "ac_vendor_r", "ac_vendor_u"])}
                            {/*drop down menue*/}
                            {SideNavUtilities.dropDownMenu(
                                {
                                    name: "Accounts",
                                    icon: "A",
                                    permissions: ["ac_customer_c", "pur_u", "ac_customer_r", "ac_customer_u", "ac_vendor_c", "ac_vendor_r", "ac_vendor_u", "ac_c"]
                                },
                                [{
                                    menuName: "Chalan payment",
                                    link: "/ac_ch_pay",
                                    permissions: ["ac_vendor_c", "pur_u"]
                                }, {
                                    menuName: "Vendor ledger",
                                    link: "/ac_vendorLedger",
                                    permissions: ["ac_vendor_c", "ac_vendor_r", "ac_vendor_u"]
                                }, {
                                    menuName: "Customer ledger",
                                    link: "/ac_customer_khata",
                                    permissions: ["ac_customer_c", "ac_customer_r", "ac_customer_u", "ac_customer_d"]
                                }, {
                                    menuName: "Salary pay",
                                    link: "/salary_pay",
                                    permissions: ["ac_c"]
                                }, {
                                    menuName: "Commission",
                                    link: "/commission",
                                    permissions: ["ac_c"]
                                }, {
                                    menuName: "Debit / Credit",
                                    link: "/debit_credit",
                                    permissions: ["ac_c"]
                                }, {
                                    menuName: "Loan",
                                    link: "/ac_loan",
                                    permissions: ["ac_c"]
                                }, {
                                    menuName: "User collection",
                                    link: "/user_collection",
                                    permissions: ["ac_c"]
                                },
                                ])}
                            {/********************  accounts end  ***********************/}

                            {/********************  HRM  ***********************/}
                            {/*link marker*/}
                            {SideNavUtilities.marker("HRM", ["hrm_c"])}
                            {/*drop down menue*/}
                            {SideNavUtilities.dropDownMenu(
                                {
                                    name: "HRM",
                                    icon: <TbUsers/>,
                                    permissions: ["hrm_c"]
                                },
                                [{
                                    menuName: "Staff",
                                    link: "/staff",
                                    permissions: ["hrm_c"]
                                }, {
                                    menuName: "Salary sheet",
                                    link: "/salary_sheet",
                                    permissions: ["hrm_c"]
                                },
                                ])}
                            {/********************  accounts end  ***********************/}

                            {/********************  admin  ***********************/}
                            {/*link marker*/}
                            {SideNavUtilities.marker("Admin", ["*"])}
                            {/*drop down menue*/}
                            {SideNavUtilities.dropDownMenu(
                                {
                                    name: "Admin",
                                    icon: <MdSecurity/>,
                                    permissions: ["*"]
                                },
                                [{
                                    menuName: "Report",
                                    link: "/report",
                                    permissions: ["*"]
                                }, {
                                    menuName: "Setting",
                                    link: "/setting",
                                    permissions: ["*"]
                                },
                                    {
                                        menuName: "Users",
                                        link: "/user",
                                        permissions: ["admin_c"]
                                    }
                                ])}
                            {/********************  admin end  ***********************/}

                        </div>
                    </div>
                    {/*sidebar footer*/}
                    {SideNavUtilities.footer()}
                </nav>
            </div>
        </>
    );
}

export default SideBar;
