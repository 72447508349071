import React, {Suspense} from 'react';

const DebitCreditComponent = React.lazy(() => import('../Component/DebitCreditComponent/DebitCreditComponent'));

function DebitAndCreditPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <DebitCreditComponent/>
            </Suspense>
        </>
    );
}

export default DebitAndCreditPage;