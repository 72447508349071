import {createSlice} from "@reduxjs/toolkit";

export const ProdCatUnitSlice = createSlice({
    name: "prodCatUnitSlice",
    initialState: {
        allProduct: [],
        allUnit: [],
        allCat: []
    },
    reducers: {
        // get all unit reducer
        getAllProductReducer: (state, action) => {
            state.allProduct = action.payload
        },
        // get all unit reducer
        getAllUnitReducer: (state, action) => {
            state.allUnit = action.payload
        },
        // get all category reducer
        getAllCatReducer: (state, action) => {
            state.allCat = action.payload
        }
    }
})