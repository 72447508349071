import React, {Suspense} from 'react';


const InvoiceUpdateComponent = React.lazy(() => import('../Component/InvoiceUpdateComponent/InvoiceUpdateComponent'));

function InvoiceUpdatePage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <InvoiceUpdateComponent/>
            </Suspense>
        </>
    );
}

export default InvoiceUpdatePage;