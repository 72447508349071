import React, {Suspense} from 'react';

const AllInvoicesComponent = React.lazy(() => import("../Component/AllInvoices/AllInvoicesComponent"))

function AllInvoicesPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <AllInvoicesComponent/>
            </Suspense>
        </>
    );
}

export default AllInvoicesPage;