import React, {Suspense} from 'react';

const VendorComponent = React.lazy(() => import("../Component/VendorComponent/VendorComponent"))

function VendorPage(props) {
    return (
        <>
            <Suspense fallback={<div>loading...</div>}>
                <VendorComponent/>
            </Suspense>
        </>
    );
}

export default VendorPage;