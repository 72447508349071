import React, {Suspense} from 'react';

const StaffComponent = React.lazy(() => import('../Component/StaffComponent/StaffComponent'));

function StaffPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <StaffComponent/>
            </Suspense>
        </>
    );
}

export default StaffPage;