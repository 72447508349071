import React, {Suspense} from 'react';

const ChalanTransactionComponent = React.lazy(() => import("../Component/ChalanTransactionComponent/ChalanTransactionComponent"))

function ChalanTransactionPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <ChalanTransactionComponent/>
            </Suspense>
        </>
    );
}

export default ChalanTransactionPage;