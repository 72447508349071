import {createSlice} from "@reduxjs/toolkit";

export const InvoiceSlice = createSlice({
    name: "invoiceSlice",
    initialState: {
        dateWiseInvoices: [],
        allDueInvoices: []
    },
    reducers: {
        getDateWiseInvoiceReducer: (state, action) => {
            state.dateWiseInvoices = action.payload
        },
        getAllDueInvoicesReducer: (state, action) => {
            state.allDueInvoices = action.payload
        }
    }
})