import {createSlice} from "@reduxjs/toolkit";

export const ChalanSlice = createSlice({
    name: "chalanSlice",
    initialState: {
        allChalan: [],
        pendingChalan: [],
        allDueChalan: []
    }, reducers: {
        getAllChalanReducer: (state, action) => {
            state.allChalan = action.payload
        },
        getAllPendingChalanReducer: (state, action) => {
            state.pendingChalan = action.payload
        },
        getAllDueChalanReducer: (state, action) => {
            state.allDueChalan = action.payload
        }
    }
})