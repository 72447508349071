import React, {Suspense} from 'react';

const PendingChalanComponent = React.lazy(() => import("../Component/PendingChalanComponent/PendingChalanComponent"))

function PendingChalanPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <PendingChalanComponent/>
            </Suspense>
        </>
    );
}

export default PendingChalanPage;