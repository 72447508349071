import React, {Suspense} from 'react';

const AllInvoicesComponent = React.lazy(() => import("../Component/AllInvoices/AllInvoicesComponent"))

function AllInvoiceForAccountsPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <AllInvoicesComponent is_call_from_accounts={1}/>
            </Suspense>
        </>
    );
}

export default AllInvoiceForAccountsPage;