import React, {Suspense} from 'react';
import construction from "../assets/images/construction.webp"

const ChalanUpdateAndReturnComponent = React.lazy(() => import("../Component/ChalanUpdateAndReturn/ChalanUpdateAndReturnComponent"))

function ChalanUpdatePage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <ChalanUpdateAndReturnComponent/>
            </Suspense>
        </>
    );
}

export default ChalanUpdatePage;