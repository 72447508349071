 const rootRoute = "https://showroomapi.anjumjewellers.in/"
//const rootRoute = "http://localhost:1111/"
// const rootRoute = "http://192.168.0.105:1111/"

export const login = rootRoute + "user/login"
export const updateUserGeneralSetting = rootRoute + "user/updateUserGeneralSetting"
export const userPasswordChange = rootRoute + "user/userPasswordChange"
export const userLogOutFromAllDevice = rootRoute + "user/userLogOutFromAllDevice"
export const getAllUser = rootRoute + "user/getAllUser"
export const getAllRole = rootRoute + "user/getAllRole"
export const addUser = rootRoute + "user/addUser"
export const updateUserFromAdmin = rootRoute + "user/updateUserFromAdmin"
export const resetUserPasswordFromAdmin = rootRoute + "user/resetUserPasswordFromAdmin"

export const addUnit = rootRoute + "product/addUnit"
export const getAllUnit = rootRoute + "product/getAllUnit"
export const addCategory = rootRoute + "product/addCategory"
export const getAllCategory = rootRoute + "product/getAllCategory"
export const getAllProduct = rootRoute + "product/getAllProduct"
export const getAllProductForPurchase = rootRoute + "product/getAllProductForPurchase"
export const addProduct = rootRoute + "product/addProduct"
export const updateProduct = rootRoute + "product/updateProduct"

export const getAllVendor = rootRoute + "vendor/getAllVendor"
export const getSingleVendor = rootRoute + "vendor/getSingleVendor"
export const addVendor = rootRoute + "vendor/addVendor"
export const updateVendor = rootRoute + "vendor/updateVendor"

export const addPurchase = rootRoute + "purchase/addPurchase"
export const addPrevStock = rootRoute + "purchase/addPrevStock"
export const getDateWiseChalan = rootRoute + "purchase/getDateWiseChalan"
export const getSingleChalanForPrint = rootRoute + "purchase/getSingleChalanForPrint"

export const getPendingChalan = rootRoute + "purchase/getPendingChalan"
export const chalanStock = rootRoute + "purchase/chalanStock"
export const getStock = rootRoute + "purchase/getStock"

export const getSingleChalanForUpdate = rootRoute + "purchase/getSingleChalanForUpdate"
export const chalanItemReturn = rootRoute + "purchase/chalanItemReturn"
export const chalanDetailUpdate = rootRoute + "purchase/chalanDetailUpdate"
export const changePurItemCost = rootRoute + "purchase/changePurItemCost"

export const addCustomer = rootRoute + "customer/addCustomer"
export const getAllCustomer = rootRoute + "customer/getAllCustomer"
export const getUnpinnedCustomers = rootRoute + "customer/getUnpinnedCustomers"
export const updateCustomer = rootRoute + "customer/updateCustomer"
export const customerPinUnPIn = rootRoute + "customer/customerPinUnPIn"

export const getSingleCustomerForSale = rootRoute + "customer/getSingleCustomerForSale"
export const getStockForSale = rootRoute + "sale/getStockForSale"
export const customerSale = rootRoute + "sale/customerSale"
export const localSale = rootRoute + "sale/localSale"
export const getDateToDateInvoices = rootRoute + "sale/getDateToDateInvoices"
export const getSingleInvoiceForPrint = rootRoute + "sale/getSingleInvoiceForPrint"
export const getSingleInvoiceforUpdate = rootRoute + "sale/getSingleInvoiceforUpdate"
export const customerInvoiceDetailUpdate = rootRoute + "sale/customerInvoiceDetailUpdate"
export const invoiceDetailUpdate = rootRoute + "sale/invoiceDetailUpdate"
export const invoiceItemCostChange = rootRoute + "sale/invoiceItemCostChange"
export const changeOtherCost = rootRoute + "sale/changeOtherCost"
export const invoiceItemReturn = rootRoute + "sale/invoiceItemReturn"
export const getAllDueInvoices = rootRoute + "sale/getAllDueInvoices"
export const dueCollection = rootRoute + "sale/dueCollection"
export const addDiscount = rootRoute + "sale/addDiscount"
export const refundPaymentFromInvoice = rootRoute + "sale/refundPaymentFromInvoice"
export const refundDiscFromInvoice = rootRoute + "sale/refundDiscFromInvoice"
export const getAllCustomerWiseDueInvoice = rootRoute + "customerTransaction/getAllCustomerWiseDueInvoice"
export const getCustomerDeposit = rootRoute + "customerTransaction/getCustomerDeposit"
export const getCustomerWiseInvoices = rootRoute + "customerTransaction/getCustomerWiseInvoices"
export const customerDeposit = rootRoute + "customerTransaction/customerDeposit"
export const customerDepositRefund = rootRoute + "customerTransaction/customerDepositRefund"
export const customerDepositToInvoicePay = rootRoute + "customerTransaction/customerDepositToInvoicePay"
export const makeCustomerPreviousDue = rootRoute + "customerTransaction/makeCustomerPreviousDue"
export const getCustomerWiseLedger = rootRoute + "customerTransaction/getCustomerWiseLedger"
export const allCustomerDistributableAmount = rootRoute + "customerTransaction/allCustomerDistributableAmount"
export const getAllDueChalan = rootRoute + "purchase/transaction/getAllDueChalan"
export const chalanPay = rootRoute + "purchase/transaction/chalanPay"
export const chalanDiscount = rootRoute + "purchase/transaction/chalanDiscount"
export const chalanPayReturn = rootRoute + "purchase/transaction/chalanPayReturn"
export const chalanDiscountWithdraw = rootRoute + "purchase/transaction/chalanDiscountWithdraw"
export const getVendorWiseDueChalan = rootRoute + "vendorTransaction/getVendorWiseDueChalan"
export const getVendorWiseChalan = rootRoute + "vendorTransaction/getVendorWiseChalan"
export const getSingleVendorDeposit = rootRoute + "vendorTransaction/getSingleVendorDeposit"
export const vendorDeposit = rootRoute + "vendorTransaction/vendorDeposit"
export const vendorDepositRefund = rootRoute + "vendorTransaction/vendorDepositRefund"
export const depositToChalanDuePay = rootRoute + "vendorTransaction/depositToChalanDuePay"
export const addVendorPrevDue = rootRoute + "vendorTransaction/addVendorPrevDue"
export const getSingleVendorLedger = rootRoute + "vendorTransaction/getSingleVendorLedger"
export const getVendorTotalDistributableAmount = rootRoute + "vendorTransaction/getVendorTotalDistributableAmount"
export const getAllStaff = rootRoute + "admin/getAllStaff"
export const createStaff = rootRoute + "admin/createStaff"
export const staffUpdate = rootRoute + "admin/staffUpdate"
export const getAllStaffForSalarySheet = rootRoute + "salary/getAllStaffForSalarySheet"
export const generateSalarySheet = rootRoute + "salary/generateSalarySheet"
export const salaryPay = rootRoute + "salary/salaryPay"
export const monthlyProdPurAndCommissionSetOrNot = rootRoute + "accounts/monthlyProdPurAndCommissionSetOrNot"
export const makeProductCommission = rootRoute + "accounts/makeProductCommission"
export const credit = rootRoute + "accounts/credit"
export const debit = rootRoute + "accounts/debit"
export const getAllLenderAndLoan = rootRoute + "accounts/getAllLenderAndLoan"
export const getUnpinnedLenderAndLoan = rootRoute + "accounts/getUnpinnedLenderAndLoan"
export const addLender = rootRoute + "accounts/addLender"
export const updateLender = rootRoute + "accounts/updateLender"
export const pinLender = rootRoute + "accounts/pinLender"
export const unpinLender = rootRoute + "accounts/unpinLender"
export const addLoan = rootRoute + "accounts/addLoan"
export const addPrevLoan = rootRoute + "accounts/addPrevLoan"
export const loanPay = rootRoute + "accounts/loanPay"
export const getLenderLoanLedger = rootRoute + "accounts/getLenderLoanLedger"
export const singleUserIncomeAndExpSummery = rootRoute + "report/singleUserIncomeAndExpSummery"
export const getAllUserForGetTransSummery = rootRoute + "report/getAllUserForGetTransSummery"
export const myBusiness = rootRoute + "report/myBusiness"
export const vendorDueReport = rootRoute + "report/vendorDueReport"
export const customerDueReport = rootRoute + "report/customerDueReport"
export const getCurrentStockAsset = rootRoute + "report/getCurrentStockAsset"
export const currentLoanReport = rootRoute + "report/currentLoanReport"
export const getDateToDateSaleItemReport = rootRoute + "report/getDateToDateSaleItemReport"
export const dateToDatePurchaseReport = rootRoute + "report/dateToDatePurchaseReport"
export const vendorAllocableReport = rootRoute + "report/vendorAllocableReport"
export const customerAllocableReport = rootRoute + "report/customerAllocableReport"
export const invoiceCollectionHistory = rootRoute + "report/invoiceCollectionHistory"
export const customerDepositHistory = rootRoute + "report/customerDepositHistory"
export const extraCreditHistory = rootRoute + "report/extraCreditHistory"
export const loanHistory = rootRoute + "report/loanHistory"
export const chalanPayRefundHistory = rootRoute + "report/chalanPayRefundHistory"
export const vendorDepositRefundHistory = rootRoute + "report/vendorDepositRefundHistory"
export const productCommissionHistory = rootRoute + "report/productCommissionHistory"
export const getInvoiceCollectionRefundHistory = rootRoute + "report/getInvoiceCollectionRefundHistory"
export const getCustomerDepositRefundHistory = rootRoute + "report/getCustomerDepositRefundHistory"
export const getExtraDebitHistory = rootRoute + "report/getExtraDebitHistory"
export const getLoanPayHistory = rootRoute + "report/getLoanPayHistory"
export const getChalanPayHistory = rootRoute + "report/getChalanPayHistory"
export const getVendorDepositHistory = rootRoute + "report/getVendorDepositHistory"
export const getSalaryPayHistory = rootRoute + "report/getSalaryPayHistory"
export const getMonthlySaleAndPurchaseData = rootRoute + "report/getMonthlySaleAndPurchaseData"
export const getUserSaleStatisticsForThisWeek = rootRoute + "report/getUserSaleStatisticsForThisWeek"
export const getUserPurStatisticsForThisWeek = rootRoute + "report/getUserPurStatisticsForThisWeek"