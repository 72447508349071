import React, {Suspense} from 'react';

const StockedItemComponent = React.lazy(() => import('../Component/StockedItemComponent/StockedItemComponent'));

function StockedItemPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <StockedItemComponent/>
            </Suspense>
        </>
    );
}

export default StockedItemPage;