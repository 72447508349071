import React, {Suspense} from 'react';

const PurchaseComponent = React.lazy(() => import('../Component/PurchaseComponent/PurchaseComponent'));

function AddPrevisousStockPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <PurchaseComponent is_prev_stock={1}/>
            </Suspense>
        </>
    );
}

export default AddPrevisousStockPage;