import React, {Suspense} from 'react';

const SaleComponent = React.lazy(() => import('../Component/SaleComponent/SaleComponent'));

function LocalSalePage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <SaleComponent isCustomerSale={0}/>
            </Suspense>
        </>
    );
}

export default LocalSalePage;