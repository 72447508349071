import {Store} from "../Redux/Store";
import {AuthSlice} from "../Redux/Slice/AuthSlice";

class Auth {
    constructor() {
    }

    // session storage
    static storage(data = {}) {
        try {
            localStorage.setItem("token", data.token)
            localStorage.setItem("userdetail", JSON.stringify(data.userdetail || {}))
            localStorage.setItem('permission', JSON.stringify(data.userdetail.role.permission))
            Store.dispatch(AuthSlice.actions.setSessionIsChangedReducer(!Store.getState()?.authSlice.sessionIsChanged))
        } catch (e) {

        }
    }

    // making header
    static header() {
        try {
            // getting parameter
            let token = localStorage.getItem("token")
            let detail = JSON.parse(localStorage.getItem("userdetail"))
            let string_perm = localStorage.getItem("permission")

            // checking is already ok or not
            if (
                token.length != 0 &&
                typeof detail == "object" &&
                Object.keys(detail).length != 0 &&
                JSON.stringify(detail.role.permission) == string_perm
            ) {
                // return data by header format
                return {
                    token: token,
                    userdetail: JSON.stringify(detail)
                }
            } else { // if some thing went wrong with session
                // set logged status false for redirect
                Store.dispatch(AuthSlice.actions.setIsLoggedReducer(false))
                return false
            }

        } catch (e) {
            // set logged status false for redirect
            Store.dispatch(AuthSlice.actions.setIsLoggedReducer(false))
            return false
        }
    }

    // session clear
    static clear() {
        localStorage.clear()
        // set logged status false for redirect
        Store.dispatch(AuthSlice.actions.setIsLoggedReducer(false))
    }
}

export default Auth