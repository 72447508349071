import {createSlice} from "@reduxjs/toolkit";

export const CustomerSlice = createSlice({
    name: "customerSlice",
    initialState: {
        allCustomer: []
    },
    reducers: {
        getAllCustomerReducer: (state, action) => {
            state.allCustomer = action.payload
        }
    }
})