import React, {Suspense} from 'react';

const UserCollectionComponent = React.lazy(() => import('../Component/UserCollectionComponent/UserCollectionComponent'));

function UserCollectionPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <UserCollectionComponent/>
            </Suspense>
        </>
    );
}

export default UserCollectionPage;