import {createSlice} from "@reduxjs/toolkit";

export const VendorSlice = createSlice({
    name: 'vendorSlice',
    initialState: {
        allVendor: []
    },
    reducers: {
        // get all vendor reducer
        getAllVendorReducer: (state, action) => {
            state.allVendor = action.payload
        }
    }
})