import React, {Suspense} from 'react';

const AllChalanComponent = React.lazy(() => import("../Component/AllChalan/AllChalanComponent"))

function AllChalanPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <AllChalanComponent/>
            </Suspense>
        </>
    );
}

export default AllChalanPage;