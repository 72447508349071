import {createSlice} from "@reduxjs/toolkit";

export const StockedItemSlice = createSlice({
    name: "stockedItemSlice",
    initialState: {
        allStockedItem: []
    },
    reducers: {
        getStockReducer: (state, action) => {
            state.allStockedItem = action.payload
        }
    }
})