import React, {Suspense} from 'react';

const PurchaseComponent = React.lazy(() => import('../Component/PurchaseComponent/PurchaseComponent'));

function PurchasePage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <PurchaseComponent/>
            </Suspense>
        </>
    );
}

export default PurchasePage;