import React, {Suspense} from 'react';

const CustomerComponent = React.lazy(() => import("../Component/CustomerComponent/CustomerComponent"))

function CustomerPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <CustomerComponent/>
            </Suspense>
        </>
    );
}

export default CustomerPage;