import React, {Suspense} from 'react';


const InvoiceDueCollectionComponent = React.lazy(() => import('../Component/InvoiceTransactionComponent/InvoiceTransactionComponent'));

function InvoiceDueCollectionPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <InvoiceDueCollectionComponent/>
            </Suspense>
        </>
    );
}

export default InvoiceDueCollectionPage;