import React, {Suspense} from 'react';

const VendorKhataComponent = React.lazy(() => import('../Component/VendorKhataComponent/VendorKhataComponent'));

function VendorKhataPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <VendorKhataComponent/>
            </Suspense>
        </>
    );
}

export default VendorKhataPage;