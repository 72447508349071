import React, {Suspense} from 'react';

const SalaryPaymentAndSheetComponent = React.lazy(() => import('../Component/SalaryPaymentAndSheetComponent/SalaryPaymentAndSheetComponent'));

function SalaryPayPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <SalaryPaymentAndSheetComponent is_call_from_salary_pay_page={1}/>
            </Suspense>
        </>
    );
}

export default SalaryPayPage;