import React, {Suspense, useEffect} from 'react';



const DashboardComponent = React.lazy(() => import("../Component/DashboardComponent/DashboardComponent"))

function DashboardPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <DashboardComponent/>
            </Suspense>
        </>
    );
}

export default DashboardPage;