import React, {Suspense} from 'react';

const CustomerKhataComponent = React.lazy(() => import("../Component/CustomerKhataComponent/CustomerKhataComponent"))

function CustomerKhataPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <CustomerKhataComponent/>
            </Suspense>
        </>
    );
}

export default CustomerKhataPage;