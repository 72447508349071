import {createSlice} from "@reduxjs/toolkit";

export const StaffSlice = createSlice({
    name: "staffSlice",
    initialState: {
        getAllStaff: []
    },
    reducers: {
        getAllStaffReducer: (state, action) => {
            state.getAllStaff = action.payload
        }
    }

})