import React, {Suspense} from 'react';
const ReportComponent = React.lazy(() => import('../Component/ReportComponent/ReportComponent'));
function ReportPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <ReportComponent/>
            </Suspense>
        </>
    );
}

export default ReportPage;