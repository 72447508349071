import React, {Suspense} from 'react';

const LoanComponent = React.lazy(() => import('../Component/LoanComponent/LoanComponent'));

function LoanPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <LoanComponent/>
            </Suspense>
        </>
    );
}

export default LoanPage;