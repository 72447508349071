import React from 'react';
import {
    Routes,
    Route,
} from "react-router-dom";
import DashboardPage from "../Pages/DashboardPage";
import LoginPage from "../Pages/LoginPage";
import {Navigate} from "react-router";
import ProtectedRoute from "./Utilities/ProtectedRoute";
import ProductPage from "../Pages/ProductPage";
import VendorPage from "../Pages/VendorPage";
import PurchasePage from "../Pages/PurchasePage";
import AllChalanPage from "../Pages/AllChalanPage";
import ChalanUpdatePage from "../Pages/ChalanUpdatePage";
import PendingChalanPage from "../Pages/PendingChalanPage";
import StockedItemPage from "../Pages/StockedItemPage";
import CustomerPage from "../Pages/CustomerPage";
import CustomerSalePage from "../Pages/CustomerSalePage";
import LocalSalePage from "../Pages/LocalSalePage";
import AllInvoicesPage from "../Pages/AllInvoicesPage";
import InvoiceUpdatePage from "../Pages/InvoiceUpdatePage";
import InvoiceDueCollectionPage from "../Pages/InvoiceDueCollectionPage";
import InvoiceCollectionPage from "../Pages/InvoiceCollectionPage";
import AllInvoiceForAccountsPage from "../Pages/AllInvoiceForAccountsPage";
import CustomerKhataPage from "../Pages/CustomerKhataPage";
import ChalanTransactionPage from "../Pages/ChalanTransactionPage";
import AllChalanForAccountsPage from "../Pages/AllChalanForAccountsPage";
import VendorKhataPage from "../Pages/VendorKhataPage";
import AddPrevisousStockPage from "../Pages/AddPrevisousStockPage";
import StaffPage from "../Pages/StaffPage";
import SalarySheetPage from "../Pages/SalarySheetPage";
import SalaryPayPage from "../Pages/SalaryPayPage";
import ProductTargetAndCommissionPage from "../Pages/ProductTargetAndCommissionPage";
import DebitAndCreditPage from "../Pages/DebitAndCreditPage";
import ReportPage from "../Pages/ReportPage";
import UserCollectionPage from "../Pages/UserCollectionPage";
import LoanPage from "../Pages/LoanPage";
import UserSettingPage from "../Pages/UserSettingPage";
import UserPage from "../Pages/UserPage";

function AppRoute(props) {
    return (
        <>
            <Routes>
                <Route path='/' element={<Navigate to={'/dashboard'}/>}/>
                {/*login route*/}
                <Route path='/login' element={<LoginPage/>}/>
                {/*dashboard route*/}
                <Route path='/dashboard' element={<ProtectedRoute permissions={["*"]}>
                    <DashboardPage/>
                </ProtectedRoute>}/>
                {/*product route*/}
                <Route path='/product' element={<ProtectedRoute
                    permissions={["prodCatUnit_c", "prodCatUnit_r", "prodCatUnit_u", "prodCatUnit_d"]}>
                    <ProductPage/>
                </ProtectedRoute>}/>
                {/*vendor route*/}
                <Route path='/vendor' element={<ProtectedRoute
                    permissions={["vendor_c", "vendor_r", "vendor_u", "vendor_d"]}>
                    <VendorPage/>
                </ProtectedRoute>}/>
                {/*purchase route*/}
                <Route path='/purchase' element={<ProtectedRoute
                    permissions={["pur_c"]}>
                    <PurchasePage/>
                </ProtectedRoute>}/>
                {/*purchase route*/}
                <Route path='/chalanUpdate' element={<ProtectedRoute
                    permissions={["pur_u"]}>
                    <ChalanUpdatePage/>
                </ProtectedRoute>}/>
                {/*all chalan route*/}
                <Route path='/allChalan' element={<ProtectedRoute
                    permissions={["pur_r", "pur_c", "ac_vendor_c", "pur_u"]}>
                    <AllChalanPage/>
                </ProtectedRoute>}/>

                {/*pending chalan route*/}
                <Route path='/pendingChalan' element={<ProtectedRoute
                    permissions={["stock_u"]}>
                    <PendingChalanPage/>
                </ProtectedRoute>}/>
                {/*stocked items route*/}
                <Route path='/stockedItems' element={<ProtectedRoute
                    permissions={["stock_r"]}>
                    <StockedItemPage/>
                </ProtectedRoute>}/>

                {/*add prevoious stock page*/}
                <Route path='/addPrevStock' element={<ProtectedRoute
                    permissions={["stock_c"]}>
                    <AddPrevisousStockPage/>
                </ProtectedRoute>}/>

                {/*customer page*/}
                <Route path='/customer' element={<ProtectedRoute
                    permissions={["customer_c", "customer_r", "customer_u", "customer_d"]}>
                    <CustomerPage/>
                </ProtectedRoute>}/>

                {/*customer sale*/}
                <Route path='/customerSale' element={<ProtectedRoute
                    permissions={["invoice_c"]}>
                    <CustomerSalePage/>
                </ProtectedRoute>}/>

                {/*local sale*/}
                <Route path='/localSale' element={<ProtectedRoute
                    permissions={["invoice_c"]}>
                    <LocalSalePage/>
                </ProtectedRoute>}/>

                {/*all invoice*/}
                <Route path='/allInvoices' element={<ProtectedRoute
                    permissions={["invoice_r", "invoice_c", "invoice_u", "ac_customer_c"]}>
                    <AllInvoicesPage/>
                </ProtectedRoute>}/>

                {/*invoice update*/}
                <Route path='/invoiceUpdate' element={<ProtectedRoute
                    permissions={["invoice_u"]}>
                    <InvoiceUpdatePage/>
                </ProtectedRoute>}/>

                {/*invoice due collection*/}
                <Route path='/i_collection' element={<ProtectedRoute
                    permissions={["invoice_c", "invoice_u", "ac_customer_c"]}>
                    <InvoiceDueCollectionPage/>
                </ProtectedRoute>}/>

                {/*/!*invoice due collection from accounts*!/*/}
                {/*<Route path='/ac_i_collection' element={<ProtectedRoute*/}
                {/*    permissions={["ac_customer_c"]}>*/}
                {/*    <InvoiceCollectionPage/>*/}
                {/*</ProtectedRoute>}/>*/}

                {/*/!*all invoice for accounts*!/*/}
                {/*<Route path='/ac_all_invoice' element={<ProtectedRoute*/}
                {/*    permissions={["ac_customer_c"]}>*/}
                {/*    <AllInvoiceForAccountsPage/>*/}
                {/*</ProtectedRoute>}/>*/}

                {/*customer khata*/}
                <Route path='/ac_customer_khata' element={<ProtectedRoute
                    permissions={["ac_customer_c", "ac_customer_r", "ac_customer_u"]}>
                    <CustomerKhataPage/>
                </ProtectedRoute>}/>

                {/*chalan payment component*/}
                <Route path='/ac_ch_pay' element={<ProtectedRoute
                    permissions={["ac_vendor_c", "pur_u"]}>
                    <ChalanTransactionPage/>
                </ProtectedRoute>}/>
                {/*/!*chalan payment component*!/*/}
                {/*<Route path='/ac_all_chalan' element={<ProtectedRoute*/}
                {/*    permissions={["*"]}>*/}
                {/*    <AllChalanForAccountsPage/>*/}
                {/*</ProtectedRoute>}/>*/}
                {/*vendor khata / vendor ledger*/}
                <Route path='/ac_vendorLedger' element={<ProtectedRoute
                    permissions={["ac_vendor_c", "ac_vendor_r", "ac_vendor_u"]}>
                    <VendorKhataPage/>
                </ProtectedRoute>}/>
                {/*salary pay route*/}
                <Route path='/salary_pay' element={<ProtectedRoute
                    permissions={["ac_c"]}>
                    <SalaryPayPage/>
                </ProtectedRoute>}/>
                {/*Product commission route*/}
                <Route path='/commission' element={<ProtectedRoute
                    permissions={["ac_c"]}>
                    <ProductTargetAndCommissionPage/>
                </ProtectedRoute>}/>
                {/*debit credit route*/}
                <Route path='/debit_credit' element={<ProtectedRoute
                    permissions={["ac_c"]}>
                    <DebitAndCreditPage/>
                </ProtectedRoute>}/>
                {/*loan route*/}
                <Route path='/ac_loan' element={<ProtectedRoute
                    permissions={["ac_c"]}>
                    <LoanPage/>
                </ProtectedRoute>}/>
                {/*user collection route*/}
                <Route path='/user_collection' element={<ProtectedRoute
                    permissions={["ac_c"]}>
                    <UserCollectionPage/>
                </ProtectedRoute>}/>
                {/*staff create and update route*/}
                <Route path='/staff' element={<ProtectedRoute
                    permissions={["hrm_c"]}>
                    <StaffPage/>
                </ProtectedRoute>}/>
                {/*salary sheet create route*/}
                <Route path='/salary_sheet' element={<ProtectedRoute
                    permissions={["hrm_c"]}>
                    <SalarySheetPage/>
                </ProtectedRoute>}/>

                {/*report route*/}
                <Route path='/report' element={<ProtectedRoute
                    permissions={["*"]}>
                    <ReportPage/>
                </ProtectedRoute>}/>
                {/*User setting*/}
                <Route path='/setting' element={<ProtectedRoute
                    permissions={["*"]}>
                    <UserSettingPage/>
                </ProtectedRoute>}/>
                {/*Users*/}
                <Route path='/user' element={<ProtectedRoute
                    permissions={["admin_c"]}>
                    <UserPage/>
                </ProtectedRoute>}/>

            </Routes>
        </>
    );
}

export default AppRoute;