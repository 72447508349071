import React, {Suspense} from 'react';

const ProdCatUnitComponent = React.lazy(() => import("../Component/ProdCatUnitComponent/ProdCatUnitComponent"))

function ProductPage(props) {
    return (
        <>
            <Suspense fallback={<div>loading...</div>}>
                <ProdCatUnitComponent/>
            </Suspense>
        </>
    );
}

export default ProductPage;