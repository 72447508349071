import {createSlice} from "@reduxjs/toolkit";

export const LoanSlice = createSlice({
    name: "loanSlice",
    initialState: {
        allLender: []
    },
    reducers: {
        getAllLenderReducer: (state, action) => {
            state.allLender = action.payload
        }
    }
})