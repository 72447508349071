import React, {Suspense} from 'react';

const AllChalanComponent = React.lazy(() => import("../Component/AllChalan/AllChalanComponent"))

function AllChalanForAccountsPage(props) {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <AllChalanComponent is_call_from_accounts_dropdown={1}/>
            </Suspense>
        </>
    );
}

export default AllChalanForAccountsPage;