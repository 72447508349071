import {createSlice} from "@reduxjs/toolkit";

export const DashboardSlice = createSlice({
    name: "dashboardSlice",
    initialState: {
        monthlySaleAndPurData: [],
        userSaleStatisticData: [],
        userPurStatisticData: []
    },
    reducers: {
        getMonthlySaleAndPurDataReducer: (state, action) => {
            state.monthlySaleAndPurData = action.payload
        },
        getUserSaleStatisticReducer: (state, action) => {
            state.userSaleStatisticData = action.payload
        },
        getUserPurStatisticReducer: (state, action) => {
            state.userPurStatisticData = action.payload
        }
    }
})